<template>
  <v-container fluid>
    <Header headerClass="politiche" title="Politiche per l'aria" />
    <v-container page>
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Misure di piano</h2>
          <div class="w-80">
            <p class="mt-40">
              Il Piano Regionale di Qualità dell’Aria (PQRA) prevede 47 Misure
              per i 6 ambiti di intervento: agricoltura, energia, industria,
              trasporti, riqualificazione urbana e comunicazione (quest’ultimo
              trasversale a tutte le misure). Hanno l’obiettivo di affrontare in
              modo strutturale e coordinato le problematiche dell’inquinamento,
              indicando la strada da percorrere per
              <strong>
                riportare la qualità dell’aria sul territorio piemontese ai
                valori previsti nel minor tempo possibile.
              </strong>
            </p>
          </div>
          <v-card class="half-left" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <p>
                  L’attuazione del Piano è demandata, oltre alle Misure, a
                  specifici Piani Stralcio che hanno carattere regolamentare e
                  prescrittivo. I <strong>Piani Stralcio</strong> non
                  sostituiscono né aggiungono azioni alle Misure; sono documenti
                  specialistici e interdisciplinari con la funzione di
                  specificare le Misure stesse.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card class="half-right" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <p>
                  Il problema dell’inquinamento diffuso dell’aria è complesso:
                  dipende da molteplici sorgenti che in diversi momenti emettono
                  inquinanti. Questi, reagendo tra loro, ne generano altri in
                  funzione anche delle condizioni meteorologiche (vento, pioggia
                  etc.); per questo motivo le Misure interessano
                  <strong>svariati ambiti</strong> che rappresentano i nodi del
                  problema.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div class="misure-piano">
            <div ref="agricoltura"></div>
            <v-card>
              <v-img src="../../assets/stylesheets/im/agricoltura.png"></v-img>
              <v-card-text class="text--primary">
                <p>
                  L'ambito agricoltura concorre al peggioramento della qualità
                  dell’aria principalmente attraverso produzione e impiego di
                  <strong>ammoniaca</strong>, uno dei principali precursori di
                  polveri sottili e gas. Risulta inoltre critica la pratica
                  della
                  <strong>
                    combustione in loco dei residui vegetali agricoli e
                    forestali</strong
                  >, per l’elevata quantità di polveri sottili che ne derivano.
                </p>
                <p>
                  L'ambito di intervento prevede
                  <strong>5 misure</strong> contrassegnate dalla sigla AG che
                  riguardano:
                </p>
                <div class="lista">
                  <ul>
                    <li>
                      sostegno ad investimenti per la riduzione delle emissioni
                      di ammoniaca in atmosfera;
                    </li>
                    <li>
                      sostegno all’apporto di matrici organiche in sostituzione
                      della concimazione minerale;
                    </li>
                    <li>
                      sostegno all’adozione di tecniche agronomiche per la
                      riduzione delle emissioni di ammoniaca in atmosfera;
                    </li>
                    <li>
                      riduzione delle emissioni di ammoniaca in atmosfera dal
                      comparto agricolo
                    </li>
                    <li>
                      la limitazione della combustione dei residui colturali del
                      riso in campo.
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
            <div ref="energia"></div>
            <v-card>
              <v-img src="../../assets/stylesheets/im/energia.png"></v-img>
              <v-card-text class="text--primary">
                <p>
                  Questo ambito di intervento prevede misure diversificate di
                  efficientamento energetico, al fine di
                  <strong
                    >migliorare le prestazioni di qualsiasi immobile</strong
                  >
                  dei diversi settori di riferimento: civile, pubblico,
                  terziario, residenziale, produttivo e industriale. L’obiettivo
                  è aumentare l’efficienza energetica, ossia sfruttare l’energia
                  nel modo migliore possibile.
                </p>
                <p>
                  L’Ambito Energia prevede
                  <strong>9 misure</strong> contrassegnate dalla sigla EE che
                  riguardano:
                </p>
                <div class="lista">
                  <ul>
                    <li>
                      incentivazione dello stoccaggio di energia negli invasi
                      alpini;
                    </li>
                    <li>
                      riqualificazione ed efficientamento energetico degli
                      edifici pubblici;
                    </li>
                    <li>
                      riqualificazione ed efficientamento energetico degli
                      edifici privati;
                    </li>
                    <li>
                      sviluppo del teleriscaldamento efficiente;
                    </li>
                    <li>
                      riqualificazione ed efficientamento energetico degli
                      impianti termici;
                    </li>
                    <li>
                      regolamentazione dell’utilizzo delle biomasse per
                      climatizzazione ambienti e produzione di acqua calda
                      sanitaria;
                    </li>
                    <li>
                      promozione della produzione energetica da fonti
                      rinnovabili che non prevedano il ricorso a processi di
                      combustione;
                    </li>
                    <li>
                      promozione della produzione di energia da fonte
                      rinnovabile nell’edilizia;
                    </li>
                    <li>
                      informazione e formazione sui temi del risparmio
                      energetico e dell’uso di fonti rinnovabili in edilizia.
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
            <div ref="industria"></div>
            <v-card>
              <v-img src="../../assets/stylesheets/im/industria.png"></v-img>
              <v-card-text class="text--primary">
                <p>
                  Regione Piemonte è caratterizzata da un’<strong>
                    importante presenza di imprese produttive</strong
                  >
                  , in particolare legate ai settori manifatturiero, automotive,
                  aerospaziale, metalmeccanico, tessile, che conservano
                  caratteristiche di elevata concentrazione territoriale, buon
                  livello tecnologico e notevole capacità innovativa.<br />
                  La presenza importante di attività produttive comporta
                  necessariamente degli impatti sulle diverse matrici ambientali
                  (qualità dell’aria, qualità e consumo delle risorse idriche,
                  emissioni acustiche, produzione rifiuti, soprattutto a livello
                  locale). Gli effetti sull’ambiente non si manifestano solo nel
                  sito produttivo ma durante tutto il
                  <strong>ciclo di vita del prodotto</strong>: dal recepimento
                  delle materie prime, al processo produttivo, alle fasi di
                  trasporto e distribuzione ed infine per lo smaltimento finale
                  dei rifiuti.<br />
                  La strategia di riduzione e controllo delle emissioni
                  derivanti dagli impianti produttivi è legata principalmente al
                  <strong
                    >miglioramento delle prestazioni emissive degli
                    impianti</strong
                  >, attraverso l’utilizzo di processi meno inquinanti o di
                  tecnologie di abbattimento delle emissioni.
                </p>
                <p>
                  L’ambito Industria prevede
                  <strong>5 misure</strong> contrassegnate dalla lettera EI che
                  prevedono:
                </p>
                <div class="lista">
                  <ul>
                    <li>
                      applicazione delle BAT (Best Available Techniques) ai
                      processi produttivi;
                    </li>
                    <li>
                      applicazione delle Migliori Tecniche Disponibili ai
                      processi produttivi;
                    </li>
                    <li>
                      riqualificazione ed efficientamento energetico dei
                      processi produttivi (audit energetico);
                    </li>
                    <li>
                      riduzione delle emissioni di Composti Organici Volatili
                      COV;
                    </li>
                    <li>
                      riduzione alle emissioni diffuse di polveri: cantieri,
                      off-road.
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
            <div ref="trasporti"></div>
            <v-card>
              <v-img src="../../assets/stylesheets/im/trasporti.png"></v-img>
              <v-card-text class="text--primary">
                <p>
                  Migliorare la qualità dell’aria significa guidare la
                  transizione della nostra Regione verso un
                  <strong>sistema di trasporti meno inquinante</strong>, nel
                  contesto di un’economia a basso tenore di carbonio. Per
                  favorire il cambiamento sono necessarie non solo
                  <strong>nuove soluzioni</strong> tecnologiche ma anche nuove
                  politiche, finalizzate a stimolare una trasformazione negli
                  stili di mobilità.
                </p>
                <p>
                  L’Ambito trasporti prevede
                  <strong>22 misure</strong> contrassegnate dalla sigla TR che
                  riguardano:
                </p>
                <div class="lista">
                  <ul>
                    <li>
                      promozione del Telelavoro e dello smart working e
                      dematerializzazione dei rapporti cittadino e Pubblica
                      Amministrazione;
                    </li>
                    <li>
                      ridefinizione della logistica in ambito urbano;
                    </li>
                    <li>
                      istituzione di una rete di Mobility Management per
                      favorire buone pratiche di mobilità;
                    </li>
                    <li>
                      potenziare il Sistema Ferroviario Metropolitano;
                    </li>
                    <li>
                      realizzazione prolungamento della Linea 1 della
                      Metropolitana di Torino;
                    </li>
                    <li>
                      realizzazione della Linea 2 della Metropolitana di Torino;
                    </li>
                    <li>
                      Ticketing & fidelizzazione utenza con l’obiettivo di
                      incrementare l’uso del Trasporto Pubblico Locale;
                    </li>
                    <li>
                      promozione della mobilità ciclistica;
                    </li>
                    <li>
                      estensione delle Zone a Traffico Limitato (ZTL) e delle
                      aree pedonali;
                    </li>
                    <li>
                      Piano Urbano della Mobilità Sostenibile;
                    </li>
                    <li>
                      rimodulazione accise carburanti;
                    </li>
                    <li>
                      rimodulazione della tassa automobilistica;
                    </li>
                    <li>
                      limitazione della circolazione in ambito urbano per
                      veicoli alimentati a gasolio;
                    </li>
                    <li>
                      introduzione della Congestion Charge - sistema di pedaggio
                      con biglietto d’ingresso con tariffazione differenziata;
                    </li>
                    <li>
                      Low Emission Zone – limitazione della circolazione dei
                      mezzi più inquinanti;
                    </li>
                    <li>
                      gestione delle tariffe dei parcheggi;
                    </li>
                    <li>
                      Epolitica integrata ed efficiente della sosta;
                    </li>
                    <li>
                      Elettrificazione Linee Ferroviarie;
                    </li>
                    <li>
                      rinnovo veicoli adibiti al Trasporto Pubblico Locale
                      (TPL);
                    </li>
                    <li>
                      adozione e integrazione di criteri ambientali nella
                      ripartizione dei fondi destinati al TPL– criteri di
                      premialità per l’attribuzione di contributi;
                    </li>
                    <li>
                      promozione della mobilità elettrica e del car sharing;
                    </li>
                    <li>
                      Sistemi di Trasporto intelligenti (ITS);
                    </li>
                    <li>
                      limitazione alla circolazione e all’uso dei mezzi
                      off-road.
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
            <div ref="riqualificazione"></div>
            <v-card>
              <v-img src="../../assets/stylesheets/im/riqualificazione.png">
              </v-img>
              <v-card-text class="text--primary">
                <p>
                  Quando si parla di riqualificazione urbana si intende un
                  insieme di azioni che mirano a
                  <strong>
                    recuperare e riqualificare il patrimonio edilizio
                    esistente</strong
                  >, con attenzione anche alle aree e agli spazi pubblici che
                  necessitano di intervento. Questo per restituire ai cittadini
                  le aree verdi compromesse e migliorare la qualità della vita
                  nelle zone urbane e periurbane.<br />
                  Inoltre, numerosi studi scientifici evidenziano come la
                  <strong>vegetazione</strong> possa giocare un ruolo
                  significativo nella
                  <strong>riduzione dei livelli di inquinanti</strong>
                  atmosferici. La vegetazione favorisce un miglioramento della
                  qualità dell’aria: è noto che, attraverso il processo di
                  fotosintesi, le piante assorbono anidride carbonica e
                  restituiscono ossigeno.
                </p>
                <p>
                  Per questo Ambito sono previste
                  <strong>2 misure</strong> contrassegnate dalla sigla RU che
                  riguardano:
                </p>
                <div class="lista">
                  <ul>
                    <li>
                      sostituzione edilizia degli edifici;
                    </li>
                    <li>
                      incentivazione di attività forestali compensative urbane
                      sia in termini di pianificazione che in termini di
                      sostegno economico.
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
            <div ref="comunicazione"></div>
            <v-card>
              <v-img src="../../assets/stylesheets/im/comunicazione.png">
              </v-img>
              <v-card-text class="text--primary">
                <p>
                  Le misure di comunicazione sono mirate a
                  <strong>sensibilizzare la cittadinanza</strong> sul tema della
                  qualità dell’aria. Questo per far sì che le Misure vengano
                  comprese dalla cittadinanza e possano
                  <strong
                    >generare mutamenti volontari degli stili di vita</strong
                  >; in questo modo si potranno evitare, per esempio, drastiche
                  misure di riduzione della mobilità veicolare, a tutto
                  vantaggio della collettività.
                </p>
                <p>
                  Per questo Ambito trasversale sono previste
                  <strong>4 misure</strong> relative a:
                </p>
                <div class="lista">
                  <ul>
                    <li>
                      informazione sulla qualità dell’aria e sui rischi per la
                      salute umana;
                    </li>
                    <li>
                      promozione di campagne di sensibilizzazione rivolte alla
                      cittadinanza;
                    </li>
                    <li>
                      attivazione di progetti formativi ed educativi sulle buone
                      pratiche per la cittadinanza attraverso il mondo
                      scolastico, il mondo del lavoro, il terzo settore;
                    </li>
                    <li>
                      promozione della partecipazione attiva del cittadino e
                      definizione di strumenti per la gestione dei conflitti
                      ambientali.
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuPolitiche page="misure-piano" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuPolitiche from "@/views/politiche/MenuPolitiche";

export default {
  components: {
    Header,
    MenuPolitiche
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      if (element) {
        var top = element.offsetTop - 100;
        this.$nextTick(() => window.scrollTo(0, top));
      }
    }
  },
  mounted() {
    var anchor = this.$route.params.anchor;
    this.scrollMeTo(anchor);
  }
};
</script>
